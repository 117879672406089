<template>
  <div class="sms-share-out">
    <div class="search-bar">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="editRow('add')">
        新建
      </el-button>

      <span class="label">消息标题：</span>
      <el-input placeholder="消息标题" clearable size="small" class="input" v-model="search.title">
      </el-input>

      <!-- <span class="label">最后修改时间：</span>
      <el-date-picker
        type="datetimerange"
        range-separator="-"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        size="small"
        class="date-picker"
        clearable
        v-model="updateTime"
        value-format="yyyy-MM-dd HH:mm:ss"
      >
      </el-date-picker> -->

      <el-button type="primary" size="small" class="btn" icon="el-icon-search" @click="searchList">
        查询
      </el-button>

      <el-button size="small" class="btn" icon="el-icon-refresh-left" @click="resetSearch">
        重置
      </el-button>
    </div>

    <el-table
      :data="tableData"
      style="width: 100%"
      class="table"
      border
      :header-cell-style="{ 'background-color': '#f5f7fa' }"
    >
      <el-table-column prop="customMsgId" label="消息ID" width="100"> </el-table-column>
      <el-table-column prop="title" label="消息标题" width="120"> </el-table-column>
      <el-table-column label="消息卡片封面图" width="130">
        <template slot-scope="scope">
          <img :src="processOssImage(scope.row.mediaSrc)" style="width: 100px; height: 100px" />
        </template>
      </el-table-column>
      <el-table-column prop="pagepath" label="小程序页面路径"> </el-table-column>
      <el-table-column prop="updateTime" label="最后修改时间"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="250">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editRow('update', scope.row)">
            编辑
          </el-button>

          <el-button
            type="text"
            size="small"
            @click="createWxUrlLink(scope.row)"
            :loading="scope.row.createWxUrlLinkLoading"
          >
            生成并复制链接
          </el-button>
          <el-button type="text" size="small" @click="deleteRow(scope.row)"> 删除 </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="footer">
      <el-pagination
        background
        layout="total,prev,pager,next,sizes"
        :total="total"
        :page-sizes="[10, 50, 100, 200]"
        @size-change="pageSizeChange"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="editDialogVisible"
      width="600px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        label-position="right"
        label-width="160px"
        :model="formData"
        :rules="rules"
        size="small"
        ref="editForm"
      >
        <el-form-item label="消息标题：" prop="title">
          <el-input v-model="formData.title"></el-input>
        </el-form-item>

        <el-form-item label="小程序页面路径：" prop="pagepath">
          <el-input v-model="formData.pagepath"></el-input>
          <div style="color: #999; font-size: 12px">默认首页：/pages/index/index</div>
        </el-form-item>

        <el-form-item label="消息封面：" prop="fileList">
          <el-upload
            action="#"
            list-type="picture-card"
            :on-remove="handleFileRemove"
            accept="image/*"
            :auto-upload="false"
            :file-list="formData.fileList"
            :on-change="handleFileChange"
            :limit="1"
          >
            <i class="el-icon-plus"></i>
          </el-upload>

          <span style="color: #999; font-size: 12px">
            只能上传一张封面，建议大小为 520*416，不超过2MB
          </span>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitForm" size="small" :loading="editFormLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  uploadImage,
  getPageList,
  addMsgItem,
  updateMsgItem,
  deleteMsgItem,
  createWxSMSUrlLink
} from '@/api/smsShareOut.js';

export default {
  data() {
    const checkFileList = (rule, value, callback) => {
      if (!value || this.formData.fileList.length === 0) return callback(new Error('请选择封面'));
      return callback();
    };

    const checkPagePath = (rule, value, callback) => {
      if (!value) return callback(new Error('请输入页面路径'));
      if (!value.startsWith('/')) return callback(new Error('页面路径格式错误'));
      return callback();
    };

    return {
      tableData: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      search: {
        title: ''
      },
      editDialogVisible: false,
      updateTime: '',
      selectedItem: {},
      formData: {
        customMsgId: '',
        title: '',
        fileList: [],
        pagepath: '',
        mediaSrc: ''
      },
      rules: {
        title: { required: true, message: '请输入消息标题', trigger: 'blur' },
        fileList: { required: true, validator: checkFileList },
        pagepath: { required: true, validator: checkPagePath, trigger: 'blur' }
      },
      dialogTitle: '新增/编辑消息',
      editFormLoading: false
    };
  },
  computed: {
    searchCondition() {
      const obj = { ...this.search };

      if (Array.isArray(this.updateTime) && this.updateTime.length > 1) {
        obj['updateTimeStart'] = this.updateTime[0];
        obj['updateTimeEnd'] = this.updateTime[1];
      }

      return {
        data: obj,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    deleteRow(row) {
      this.$confirm(`确定删除吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteMsgItem({ data: row.customMsgId })
            .then((res) => {
              if (!res) {
                this.$message.error('操作失败');
                return;
              }

              this.$message.success('操作成功');
              this.getList();
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },
    resetSearch() {
      Object.keys(this.search).forEach((el) => {
        this.search[el] = '';
      });

      this.updateTime = [];
      this.pageNumber = 1;
      this.getList();
    },
    getList() {
      getPageList(this.searchCondition)
        .then((res) => {
          const { content, total } = res || {};
          this.tableData = (content || []).map((el) => ({ ...el, createWxUrlLinkLoading: false }));
          this.total = Number(total || '0');
        })
        .catch((err) => console.log(err));
    },
    pageSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    pageChange(val) {
      this.pageNumber = val;
      this.getList();
    },
    searchList() {
      this.pageNumber = 1;
      this.getList();
    },
    editRow(type, row) {
      this.editDialogVisible = true;
      this.dialogTitle = `${type === 'update' ? '编辑' : '新增'}消息`;

      this.$nextTick(() => {
        this.$refs.editForm.resetFields();

        if (type === 'update') {
          this.formData = { ...row };
          this.formData.fileList = [{ name: row.mediaSrc, url: row.mediaSrc }];
        }

        if (type === 'add') {
          this.formData = {
            customMsgId: '',
            title: '',
            fileList: [],
            pagepath: '',
            mediaSrc: ''
          };
        }
      });
    },
    submitForm() {
      this.$refs.editForm.validate(async (valid) => {
        try {
          if (!valid) return;
          this.editFormLoading = true;

          const coverUrl = this.formData.fileList[0].url || this.formData.mediaSrc;

          if (!coverUrl.startsWith('https')) {
            const uploadRes = await uploadImage(this.formData.fileList[0].raw);
            this.formData.mediaSrc = uploadRes.http;
          }

          const params = { data: { ...this.formData, msgType: 'miniprogrampage' } };
          const editFunc = this.formData.customMsgId ? updateMsgItem : addMsgItem;
          const res = await editFunc(params);

          if (res) {
            this.editDialogVisible = false;
            this.$message.success('操作成功');
            this.getList();
            return;
          }

          this.$message.error('操作失败');
        } catch (err) {
          console.log(err);
        } finally {
          this.editFormLoading = false;
        }
      });
    },
    handleFileRemove(file, _) {
      this.formData.fileList = this.formData.fileList.filter((el) => el.url !== file.url);
    },
    handleFileChange(_, files) {
      this.formData.fileList = files;
    },
    createWxUrlLink(row) {
      const index = this.tableData.findIndex((el) => el.customMsgId === row.customMsgId);

      if (index <= -1) return;
      row.createWxUrlLinkLoading = true;
      this.$set(this.tableData, index, row);

      const params = {
        data: {
          path: '/pages/sms-share-out/sms-share-out',
          query: `customMsgId=${row.customMsgId}`,
          is_expire: true,
          expire_type: 1,
          expire_interval: 15,
          env_version: process.env.NODE_ENV === 'development' ? 'trial' : 'release'
        }
      };

      createWxSMSUrlLink(params)
        .then((res) => {
          if (!res) return;
          this.$copyText(res);
          this.$message.success('创建链接成功，已复制到剪贴板');
        })
        .catch((err) => console.log(err))
        .finally(() => {
          row.createWxUrlLinkLoading = false;
          this.$set(this.tableData, index, row);
        });
    },
    processOssImage(url) {
      try {
        if (!url) return url;
        const urlArr = url.split('?');
        return `${urlArr[0]}?x-oss-process=image/resize,w_260,m_lfit`;
      } catch {
        return url;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.sms-share-out {
  background-color: white;
  padding: 20px;

  .search-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > :nth-child(n) {
      margin-top: 20px;
    }

    .label {
      font-size: 14px;
      margin-left: 15px;
    }

    .select {
      width: 120px;
    }

    .input {
      width: 200px;
    }

    .date-picker {
      width: 350px;
    }

    .btn {
      margin-left: 15px;
    }
  }

  .table {
    margin-top: 20px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 0 0;
  }

  .dialog-footer {
    text-align: right;
  }

  /deep/ .el-upload-list__item {
    transition: none !important;
  }
}
</style>
