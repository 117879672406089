import request from '@/utils/request';

export function uploadImage(file) {
  const formData = new FormData();
  formData.append('file', file);

  return request({
    url: 'misc-web-api/common/file/uploadImage',
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function getPageList(data) {
  return request({
    url: 'misc-web-api/admin/wx/custom/message/findPageList',
    method: 'POST',
    data
  });
}

export function addMsgItem(data) {
  return request({
    url: 'misc-web-api/admin/wx/custom/message/add',
    method: 'POST',
    data
  });
}

export function updateMsgItem(data) {
  return request({
    url: 'misc-web-api/admin/wx/custom/message/updateById',
    method: 'POST',
    data
  });
}

export function deleteMsgItem(data) {
  return request({
    url: 'misc-web-api/admin/wx/custom/message/deleteById',
    method: 'POST',
    data
  });
}

export function createWxSMSUrlLink(data) {
  return request({
    url: 'misc-web-api/admin/wx/url/link/generateUrlLink',
    method: 'POST',
    data
  });
}

export default {};
